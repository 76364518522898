<template>
  <div class="row auth-row">
    <div class="col-12 col-lg-6">
      <h1 class="mb-2 ms-4 color-primary">Create an account</h1>
      <div class="auth-img">
        <app-image src="batters-info.png" alt="Batters Lifecycle" />
      </div>
    </div>
    <div class="col-12 col-lg-6 form-col">
      <app-form
        :form="form"
        :model="model"
        :rules="rules"
        button="Register"
        @submitted="handleSubmit"
      />
      <p class="mt-4 fs-16">
        Already have an account?
        <span class="inline-link" @click="$emit('showLogin', true)">Login</span>
      </p>
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
import { form, model, rules } from '@/utils/config/register';

export default {
  name: 'AuthRegister',
  emits: ['showLogin'],
  data() {
    return {
      form: [],
      model: {},
      rules: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));
  },
  methods: {
    async handleSubmit(data) {
      const status = await Database.register({
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        email: data.email.toLowerCase(),
        password: data.password,
        company: data.company || null,
      });

      if (status === 201)
        this.$router.push({
          name: 'Success',
          params: { type: 'registration' },
        });
      else
        return this.$message({
          type: 'error',
          message: 'Something went wrong. Please check all the fields.',
          offset: 200,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-row {
  .form-col {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

@include bp-down(lg) {
  .auth-row {
    .form-col {
      max-height: 100%;
      overflow-y: auto;
      margin-top: 2rem;
    }
  }
}
</style>
