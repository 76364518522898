<template>
  <div class="container">
    <auth-login v-if="showLogin" @showLogin="toggleLogin" />
    <auth-register v-else @showLogin="toggleLogin"  />
  </div>
</template>

<script>
import AuthLogin from '@/components/auth/Login';
import AuthRegister from '@/components/auth/Register';

export default {
  name: 'Auth',
  components: {
    AuthLogin,
    AuthRegister,
  },
  data() {
    return {
      showLogin: true,
    };
  },
  methods: {
    toggleLogin(state) {
      this.showLogin = state;
    },
  }
};
</script>
